import {
	emailFieldRegex,
	nameFieldRegex,
	postalCodeRegex,
	privacyPolicyRegex,
	textFieldRegex
} from 'components/newMyProperty/utils/constants';

export const changedPasswordInput = [
	{
		name: 'password',
		label: 'Neues Passwort',
		placeholder: 'Neues Passwort',
		type: 'password',
		from: 'signup'
	},
	{
		name: 'oldPwd',
		label: 'Altes Passwort',
		placeholder: 'Altes Passwort',
		type: 'password',
		from: 'reset'
	}
];

export const countriesArray = [
	{ label: 'Deutschland', value: 'Deutschland', id: 'Deutschland' },
	{ label: 'Schweiz', value: 'Schweiz', id: 'Schweiz' },
	{ label: 'Österreich', value: 'Österreich', id: 'Österreich' },
	{ label: 'Afghanistan', value: 'Afghanistan', id: 'Afghanistan' },
	{ label: 'Ägypten', value: 'Ägypten', id: 'Ägypten' },
	{ label: 'Albanien', value: 'Albanien', id: 'Albanien' },
	{ label: 'Algerien', value: 'Algerien', id: 'Algerien' },
	{ label: 'Andorra', value: 'Andorra', id: 'Andorra' },
	{ label: 'Angola', value: 'Angola', id: 'Angola' },
	{
		label: 'Antigua und Barbuda',
		value: 'Antigua und Barbuda',
		id: 'Antigua und Barbuda'
	},
	{
		label: 'Äquatorialguinea',
		value: 'Äquatorialguinea',
		id: 'Äquatorialguinea'
	},
	{ label: 'Argentinien', value: 'Argentinien', id: 'Argentinien' },
	{ label: 'Armenien', value: 'Armenien', id: 'Armenien' },
	{ label: 'Aserbaidschan', value: 'Aserbaidschan', id: 'Aserbaidschan' },
	{ label: 'Äthiopien', value: 'Äthiopien', id: 'Äthiopien' },
	{ label: 'Australien', value: 'Australien', id: 'Australien' },
	{ label: 'Bahamas', value: 'Bahamas', id: 'Bahamas' },
	{ label: 'Bahrain', value: 'Bahrain', id: 'Bahrain' },
	{ label: 'Bangladesch', value: 'Bangladesch', id: 'Bangladesch' },
	{ label: 'Barbados', value: 'Barbados', id: 'Barbados' },
	{ label: 'Belgien', value: 'Belgien', id: 'Belgien' },
	{ label: 'Belize', value: 'Belize', id: 'Belize' },
	{ label: 'Benin', value: 'Benin', id: 'Benin' },
	{ label: 'Bhutan', value: 'Bhutan', id: 'Bhutan' },
	{ label: 'Bolivien', value: 'Bolivien', id: 'Bolivien' },
	{
		label: 'Bosnien und Herzegowina',
		value: 'Bosnien und Herzegowina',
		id: 'Bosnien und Herzegowina'
	},
	{ label: 'Botsuana', value: 'Botsuana', id: 'Botsuana' },
	{ label: 'Brasilien', value: 'Brasilien', id: 'Brasilien' },
	{ label: 'Brunei', value: 'Brunei', id: 'Brunei' },
	{ label: 'Bulgarien', value: 'Bulgarien', id: 'Bulgarien' },
	{ label: 'Burkina Faso', value: 'Burkina Faso', id: 'Burkina Faso' },
	{ label: 'Burundi', value: 'Burundi', id: 'Burundi' },
	{ label: 'Chile', value: 'Chile', id: 'Chile' },
	{
		label: 'China Republik (Taiwan)',
		value: 'China Republik (Taiwan)',
		id: 'China Republik (Taiwan)'
	},
	{
		label: 'China Volksrepublik',
		value: 'China Volksrepublik',
		id: 'China Volksrepublik'
	},
	{ label: 'Cookinseln', value: 'Cookinseln', id: 'Cookinseln' },
	{ label: 'Costa Rica', value: 'Costa Rica', id: 'Costa Rica' },
	{ label: 'Dänemark', value: 'Dänemark', id: 'Dänemark' },
	{ label: 'Dominica', value: 'Dominica', id: 'Dominica' },
	{
		label: 'Dominikanische Republik',
		value: 'Dominikanische Republik',
		id: 'Dominikanische Republik'
	},
	{ label: 'Dschibuti', value: 'Dschibuti', id: 'Dschibuti' },
	{ label: 'Ecuador', value: 'Ecuador', id: 'Ecuador' },
	{ label: 'El Salvador', value: 'El Salvador', id: 'El Salvador' },
	{ label: 'Elfenbeinküste', value: 'Elfenbeinküste', id: 'Elfenbeinküste' },
	{ label: 'England', value: 'England', id: 'England' },
	{ label: 'Eritrea', value: 'Eritrea', id: 'Eritrea' },
	{ label: 'Estland', value: 'Estland', id: 'Estland' },
	{ label: 'Fidschi', value: 'Fidschi', id: 'Fidschi' },
	{ label: 'Finnland', value: 'Finnland', id: 'Finnland' },
	{ label: 'Frankreich', value: 'Frankreich', id: 'Frankreich' },
	{ label: 'Gabun', value: 'Gabun', id: 'Gabun' },
	{ label: 'Gambia', value: 'Gambia', id: 'Gambia' },
	{ label: 'Georgien', value: 'Georgien', id: 'Georgien' },
	{ label: 'Ghana', value: 'Ghana', id: 'Ghana' },
	{ label: 'Grenada', value: 'Grenada', id: 'Grenada' },
	{ label: 'Griechenland', value: 'Griechenland', id: 'Griechenland' },
	{ label: 'Grossbritannien', value: 'Grossbritannien', id: 'Grossbritannien' },
	{ label: 'Guatemala', value: 'Guatemala', id: 'Guatemala' },
	{ label: 'Guinea', value: 'Guinea', id: 'Guinea' },
	{ label: 'Guinea-Bissau', value: 'Guinea-Bissau', id: 'Guinea-Bissau' },
	{ label: 'Guyana', value: 'Guyana', id: 'Guyana' },
	{ label: 'Haiti', value: 'Haiti', id: 'Haiti' },
	{ label: 'Honduras', value: 'Honduras', id: 'Honduras' },
	{ label: 'Indien', value: 'Indien', id: 'Indien' },
	{ label: 'Indonesien', value: 'Indonesien', id: 'Indonesien' },
	{ label: 'Irak', value: 'Irak', id: 'Irak' },
	{ label: 'Iran', value: 'Iran', id: 'Iran' },
	{ label: 'Irland', value: 'Irland', id: 'Irland' },
	{ label: 'Island', value: 'Island', id: 'Island' },
	{ label: 'Isle of Man', value: 'Isle of Man', id: 'Isle of Man' },
	{ label: 'Israel', value: 'Israel', id: 'Israel' },
	{ label: 'Italien', value: 'Italien', id: 'Italien' },
	{ label: 'Jamaika', value: 'Jamaika', id: 'Jamaika' },
	{ label: 'Japan', value: 'Japan', id: 'Japan' },
	{ label: 'Jemen', value: 'Jemen', id: 'Jemen' },
	{ label: 'Jordanien', value: 'Jordanien', id: 'Jordanien' },
	{ label: 'Kambodscha', value: 'Kambodscha', id: 'Kambodscha' },
	{ label: 'Kamerun', value: 'Kamerun', id: 'Kamerun' },
	{ label: 'Kanada', value: 'Kanada', id: 'Kanada' },
	{ label: 'Kap Verde', value: 'Kap Verde', id: 'Kap Verde' },
	{ label: 'Kasachstan', value: 'Kasachstan', id: 'Kasachstan' },
	{ label: 'Katar', value: 'Katar', id: 'Katar' },
	{ label: 'Kenia', value: 'Kenia', id: 'Kenia' },
	{ label: 'Kirgisistan', value: 'Kirgisistan', id: 'Kirgisistan' },
	{ label: 'Kiribati', value: 'Kiribati', id: 'Kiribati' },
	{ label: 'Kolumbien', value: 'Kolumbien', id: 'Kolumbien' },
	{ label: 'Komoren', value: 'Komoren', id: 'Komoren' },
	{
		label: 'Kongo Demokratische Republik',
		value: 'Kongo Demokratische Republik',
		id: 'Kongo Demokratische Republik'
	},
	{ label: 'Kongo Republik', value: 'Kongo Republik', id: 'Kongo Republik' },
	{
		label: 'Korea Demokratische Volksrepublik',
		value: 'Korea Demokratische Volksrepublik',
		id: 'Korea Demokratische Volksrepublik'
	},
	{ label: 'Korea Republik', value: 'Korea Republik', id: 'Korea Republik' },
	{ label: 'Kroatien', value: 'Kroatien', id: 'Kroatien' },
	{ label: 'Kuba', value: 'Kuba', id: 'Kuba' },
	{ label: 'Kuwait', value: 'Kuwait', id: 'Kuwait' },
	{ label: 'Laos', value: 'Laos', id: 'Laos' },
	{ label: 'Lesotho', value: 'Lesotho', id: 'Lesotho' },
	{ label: 'Lettland', value: 'Lettland', id: 'Lettland' },
	{ label: 'Libanon', value: 'Libanon', id: 'Libanon' },
	{ label: 'Liberia', value: 'Liberia', id: 'Liberia' },
	{ label: 'Libyen', value: 'Libyen', id: 'Libyen' },
	{ label: 'Liechtenstein', value: 'Liechtenstein', id: 'Liechtenstein' },
	{ label: 'Litauen', value: 'Litauen', id: 'Litauen' },
	{ label: 'Luxemburg', value: 'Luxemburg', id: 'Luxemburg' },
	{ label: 'Madagaskar', value: 'Madagaskar', id: 'Madagaskar' },
	{ label: 'Malawi', value: 'Malawi', id: 'Malawi' },
	{ label: 'Malaysia', value: 'Malaysia', id: 'Malaysia' },
	{ label: 'Malediven', value: 'Malediven', id: 'Malediven' },
	{ label: 'Mali', value: 'Mali', id: 'Mali' },
	{ label: 'Malta', value: 'Malta', id: 'Malta' },
	{ label: 'Marokko', value: 'Marokko', id: 'Marokko' },
	{ label: 'Marshallinseln', value: 'Marshallinseln', id: 'Marshallinseln' },
	{ label: 'Mauretanien', value: 'Mauretanien', id: 'Mauretanien' },
	{ label: 'Mauritius', value: 'Mauritius', id: 'Mauritius' },
	{ label: 'Mazedonien', value: 'Mazedonien', id: 'Mazedonien' },
	{ label: 'Mexiko', value: 'Mexiko', id: 'Mexiko' },
	{ label: 'Mikronesien', value: 'Mikronesien', id: 'Mikronesien' },
	{ label: 'Moldawien', value: 'Moldawien', id: 'Moldawien' },
	{ label: 'Monaco', value: 'Monaco', id: 'Monaco' },
	{ label: 'Mongolei', value: 'Mongolei', id: 'Mongolei' },
	{ label: 'Montenegro', value: 'Montenegro', id: 'Montenegro' },
	{ label: 'Mosambik', value: 'Mosambik', id: 'Mosambik' },
	{ label: 'Myanmar', value: 'Myanmar', id: 'Myanmar' },
	{ label: 'Namibia', value: 'Namibia', id: 'Namibia' },
	{ label: 'Nauru', value: 'Nauru', id: 'Nauru' },
	{ label: 'Nepal', value: 'Nepal', id: 'Nepal' },
	{ label: 'Neuseeland', value: 'Neuseeland', id: 'Neuseeland' },
	{ label: 'Nicaragua', value: 'Nicaragua', id: 'Nicaragua' },
	{ label: 'Niederlande', value: 'Niederlande', id: 'Niederlande' },
	{ label: 'Niger', value: 'Niger', id: 'Niger' },
	{ label: 'Nigeria', value: 'Nigeria', id: 'Nigeria' },
	{ label: 'Niue', value: 'Niue', id: 'Niue' },
	{ label: 'Norwegen', value: 'Norwegen', id: 'Norwegen' },
	{ label: 'Österreich', value: 'Österreich', id: 'Österreich' },
	{ label: 'Oman', value: 'Oman', id: 'Oman' },
	{ label: 'Osttimor', value: 'Osttimor', id: 'Osttimor' },
	{ label: 'Pakistan', value: 'Pakistan', id: 'Pakistan' },
	{
		label: 'Palästinensische Autonomiegebiete',
		value: 'Palästinensische Autonomiegebiete',
		id: 'Palästinensische Autonomiegebiete'
	},
	{ label: 'Palau', value: 'Palau', id: 'Palau' },
	{ label: 'Panama', value: 'Panama', id: 'Panama' },
	{ label: 'Papua-Neuguinea', value: 'Papua-Neuguinea', id: 'Papua-Neuguinea' },
	{ label: 'Paraguay', value: 'Paraguay', id: 'Paraguay' },
	{ label: 'Peru', value: 'Peru', id: 'Peru' },
	{ label: 'Philippinen', value: 'Philippinen', id: 'Philippinen' },
	{ label: 'Polen', value: 'Polen', id: 'Polen' },
	{ label: 'Portugal', value: 'Portugal', id: 'Portugal' },
	{ label: 'Ruanda', value: 'Ruanda', id: 'Ruanda' },
	{ label: 'Rumänien', value: 'Rumänien', id: 'Rumänien' },
	{ label: 'Russland', value: 'Russland', id: 'Russland' },
	{ label: 'Salomonen', value: 'Salomonen', id: 'Salomonen' },
	{ label: 'Sambia', value: 'Sambia', id: 'Sambia' },
	{ label: 'Samoa', value: 'Samoa', id: 'Samoa' },
	{ label: 'San Marino', value: 'San Marino', id: 'San Marino' },
	{
		label: 'São Tomé und Príncipe',
		value: 'São Tomé und Príncipe',
		id: 'São Tomé und Príncipe'
	},
	{ label: 'Saudi-Arabien', value: 'Saudi-Arabien', id: 'Saudi-Arabien' },
	{ label: 'Schweden', value: 'Schweden', id: 'Schweden' },
	{ label: 'Schweiz', value: 'Schweiz', id: 'Schweiz' },
	{ label: 'Senegal', value: 'Senegal', id: 'Senegal' },
	{ label: 'Serbien', value: 'Serbien', id: 'Serbien' },
	{ label: 'Seychellen', value: 'Seychellen', id: 'Seychellen' },
	{ label: 'Sierra Leone', value: 'Sierra Leone', id: 'Sierra Leone' },
	{ label: 'Simbabwe', value: 'Simbabwe', id: 'Simbabwe' },
	{ label: 'Singapur', value: 'Singapur', id: 'Singapur' },
	{ label: 'Slowakei', value: 'Slowakei', id: 'Slowakei' },
	{ label: 'Slowenien', value: 'Slowenien', id: 'Slowenien' },
	{ label: 'Somalia', value: 'Somalia', id: 'Somalia' },
	{ label: 'Spanien', value: 'Spanien', id: 'Spanien' },
	{ label: 'Sri Lanka', value: 'Sri Lanka', id: 'Sri Lanka' },
	{
		label: 'St. Kitts und Nevis',
		value: 'St. Kitts und Nevis',
		id: 'St. Kitts und Nevis'
	},
	{ label: 'St. Lucia', value: 'St. Lucia', id: 'St. Lucia' },
	{
		label: 'St. Vincent und die Grenadinen',
		value: 'St. Vincent und die Grenadinen',
		id: 'St. Vincent und die Grenadinen'
	},
	{ label: 'Südafrika', value: 'Südafrika', id: 'Südafrika' },
	{ label: 'Sudan', value: 'Sudan', id: 'Sudan' },
	{ label: 'Suriname', value: 'Suriname', id: 'Suriname' },
	{ label: 'Swasiland', value: 'Swasiland', id: 'Swasiland' },
	{ label: 'Syrien', value: 'Syrien', id: 'Syrien' },
	{ label: 'Tadschikistan', value: 'Tadschikistan', id: 'Tadschikistan' },
	{ label: 'Tansania', value: 'Tansania', id: 'Tansania' },
	{ label: 'Thailand', value: 'Thailand', id: 'Thailand' },
	{ label: 'Togo', value: 'Togo', id: 'Togo' },
	{ label: 'Tonga', value: 'Tonga', id: 'Tonga' },
	{
		label: 'Trinidad und Tobago',
		value: 'Trinidad und Tobago',
		id: 'Trinidad und Tobago'
	},
	{ label: 'Tschad', value: 'Tschad', id: 'Tschad' },
	{ label: 'Tschechien', value: 'Tschechien', id: 'Tschechien' },
	{ label: 'Tunesien', value: 'Tunesien', id: 'Tunesien' },
	{ label: 'Türkei', value: 'Türkei', id: 'Türkei' },
	{
		label: 'Türkische Republik Nordzypern',
		value: 'Türkische Republik Nordzypern',
		id: 'Türkische Republik Nordzypern'
	},
	{ label: 'Turkmenistan', value: 'Turkmenistan', id: 'Turkmenistan' },
	{ label: 'Tuvalu', value: 'Tuvalu', id: 'Tuvalu' },
	{ label: 'Uganda', value: 'Uganda', id: 'Uganda' },
	{ label: 'Ukraine', value: 'Ukraine', id: 'Ukraine' },
	{ label: 'Ungarn', value: 'Ungarn', id: 'Ungarn' },
	{ label: 'Uruguay', value: 'Uruguay', id: 'Uruguay' },
	{ label: 'Usbekistan', value: 'Usbekistan', id: 'Usbekistan' },
	{ label: 'Vanuatu', value: 'Vanuatu', id: 'Vanuatu' },
	{ label: 'Vatikanstadt', value: 'Vatikanstadt', id: 'Vatikanstadt' },
	{ label: 'Venezuela', value: 'Venezuela', id: 'Venezuela' },
	{
		label: 'Vereinigte Arabische Emirate',
		value: 'Vereinigte Arabische Emirate',
		id: 'Vereinigte Arabische Emirate'
	},
	{
		label: 'Vereinigte Staaten von Amerika',
		value: 'Vereinigte Staaten von Amerika',
		id: 'Vereinigte Staaten von Amerika'
	},
	{ label: 'Vietnam', value: 'Vietnam', id: 'Vietnam' },
	{ label: 'Weißrussland', value: 'Weißrussland', id: 'Weißrussland' },
	{ label: 'Westsahara', value: 'Westsahara', id: 'Westsahara' },
	{
		label: 'Zentralafrikanische Republik',
		value: 'Zentralafrikanische Republik',
		id: 'Zentralafrikanische Republik'
	},
	{ label: 'Zypern', value: 'Zypern', id: 'Zypern' }
];

const errorMessage = 'Bitte füllen Sie das Feld aus';
const validInputMessage = 'Bitte eine gültige Eingabe machen';

export const rules: { [key: string]: any } = {
	gender: {
		required: { value: true, message: errorMessage }
	},
	firstName: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: nameFieldRegex,
			message: validInputMessage
		}
	},
	lastName: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: nameFieldRegex,
			message: validInputMessage
		}
	},
	email: {
		required: {
			value: true,
			message: errorMessage
		},
		pattern: {
			value: emailFieldRegex,
			message: 'Ungültige E-Mail-Adresse'
		}
	},
	phone: {
		required: { value: true, message: errorMessage }
	},
	street: {
		required: { value: true, message: errorMessage }
	},
	postalCode: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: postalCodeRegex,
			message: validInputMessage
		}
	},
	city: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: textFieldRegex,
			message: validInputMessage
		}
	},
	land: {
		required: { value: true, message: errorMessage }
	},
	moreInformation: {
		required: { value: true, message: errorMessage }
	},
	companyName: {
		required: { value: true, message: errorMessage }
	},
	imprint: {
		validate: (value: string) => {
			return (value && value.trim() !== '<p><br></p>') || errorMessage;
		}
	},
	taxIdentifier: {
		required: { value: true, message: errorMessage }
	},
	isConfirm: {
		required: { value: true, message: errorMessage }
	},
	privacyPolicy: {
		required: {
			value: true,
			message: errorMessage
		},
		pattern: {
			value: privacyPolicyRegex,
			message: 'Ungültige URL-Adresse'
		}
	}
};

export enum userRole {
	propertySeeker = 'USER',
	propertyOwner = 'PROPERTY_OWNER',
	professionalBroker = 'USER_REALTOR'
}

export const PropertySeekerRequiredFields = ['email'];
export const PropertyOwnerRequiredFields = [
	'gender',
	'firstName',
	'lastName',
	'email',
	'phone',
	'street',
	'postalCode',
	'city',
	'land'
];

export const ProfessionalBrokerRequiredFields = [
	'gender',
	'firstName',
	'lastName',
	'email',
	'phone',
	'street',
	'postalCode',
	'city',
	'land',
	'moreInformation',
	'companyName',
	'imprint',
	'taxIdentifier',
	'privacyPolicy'
];

export const radioButtonOptions = [
	{ label: 'Immobiliensuchender', value: userRole.propertySeeker },
	{ label: 'Immobilienbesitzer', value: userRole.propertyOwner },
	{ label: 'Professioneller Makler', value: userRole.professionalBroker }
];

export const GENDER_OPTIONS = [
	{ id: 'Herr', value: 'Male', label: 'Herr' },
	{ id: 'Frau', value: 'Female', label: 'Frau' }
];

export enum contactTabRole {
	propertyOwner = 'PROPERTY_OWNER',
	professionalBroker = 'USER_REALTOR'
}
export const defaultRule = {
	email: {
		maxLength: {
			value: 100,
			message: 'Zeichenbeschränkung überschritten'
		}
	},
	street: {
		maxLength: {
			value: 512,
			message: 'Zeichenbeschränkung überschritten'
		}
	},
	postalCode: {
		maxLength: {
			value: 20,
			message: 'Zeichenbeschränkung überschritten'
		}
	},
	city: {
		maxLength: {
			value: 512,
			message: 'Zeichenbeschränkung überschritten'
		}
	},
	moreInformation: {
		maxLength: {
			value: 512,
			message: 'Zeichenbeschränkung überschritten'
		}
	},
	companyName: {
		maxLength: {
			value: 512,
			message: 'Zeichenbeschränkung überschritten'
		}
	},

	taxIdentifier: {
		maxLength: {
			value: 11,
			message: 'Zeichenbeschränkung überschritten'
		}
	}
};
