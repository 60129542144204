import { userRole } from 'components/profile/utils/constants';
import React, { FC } from 'react';

interface IProps {
	userDetail: {
		companyName: string;
		gender: string;
		firstName: string;
		lastName: string;
		postalCode: string;
		city: string;
		moreInformation: string;
		taxIdentifier: string;
		imprint: string;
		roleName: string;
		websiteUrl: string;
		contactEmail: string;
		impressumWebsiteUrl: string;
		contactNumber: string;
	};
}

const ObjectUserDetail: FC<IProps> = ({ userDetail }) => {
	const {
		companyName,
		gender,
		firstName,
		lastName,
		postalCode,
		city,
		moreInformation,
		taxIdentifier,
		imprint,
		roleName,
		websiteUrl,
		contactEmail,
		impressumWebsiteUrl,
		contactNumber
	} = userDetail;

	const getGender = (gender: string) => {
		if (gender === 'Male') {
			return 'Herr';
		} else {
			return 'Frau';
		}
	};
	return (
		<div className='object-user-detail-div'>
			{(companyName || websiteUrl) && (
				<div className='flex flex--column information-div'>
					{/* <h6>Firma / Unternehmensbezeichnung:</h6> */}
					<p>{companyName || ''}</p>
					<p dangerouslySetInnerHTML={{ __html: websiteUrl || '' }}></p>
				</div>
			)}
			<div className='information-div'>
				<div className='flex align__items--center'>
					{gender && (
						<div className={'mr--5'}>
							{/* <h6>Anrede:</h6> */}
							<p>{getGender(gender) || ''}</p>
						</div>
					)}
					{firstName && (
						<div className='mr--5'>
							{/* <h6>Vorname:</h6> */}
							<p>{firstName || ''}</p>
						</div>
					)}
					{lastName && (
						<div className='mr--5'>
							{/* <h6>Nachname:</h6> */}
							<p>{lastName || ''}</p>
						</div>
					)}
				</div>
				{contactEmail && (
					<a className='font-size--15' href={`mailto:${contactEmail}`} target='_blank' rel='noreferrer'>
						{contactEmail}
					</a>
				)}
				{contactNumber && <p>{contactNumber}</p>}
				{/* <div className='flex align__items--center '>
					{postalCode && (
						<div className='mr--5'>
							<h6>Postleitzahl:</h6>
							<p>{postalCode || ''}</p>
						</div>
					)}
					{city && (
						<div className='mr--5'>
							<h6>Stadt/Ort:</h6> 
							<p>{city || ''}</p>
						</div>
					)}
				</div> */}
			</div>

			{moreInformation && (
				<div className='information-div'>
					<div className='flex align__items--center'>
						<h6>Weitere Angaben:</h6>
						<p dangerouslySetInnerHTML={{ __html: moreInformation || '' }}></p>
					</div>
				</div>
			)}

			{roleName === userRole.professionalBroker && (
				<div className='information-div'>
					{taxIdentifier && (
						<div className='flex align__items--center'>
							<h6>Umsatzsteuer-ID:</h6>
							<p>{taxIdentifier || ''}</p>
						</div>
					)}
					{imprint && (
						<div>
							{imprint.includes('https://') || imprint.includes('http://') ? (
								<p className='imprint-link'>
									<a href={imprint} target='_blank' rel='noreferrer'>
										Impressum
									</a>
								</p>
							) : (
								<>
									<h6>Impressum:</h6>
									<div dangerouslySetInnerHTML={{ __html: imprint }}></div>
								</>
							)}
						</div>
					)}
					{!imprint && (
						<div>
							<h6>Impressum:</h6>
							<div className='flex'>
								{impressumWebsiteUrl && (
									<p>
										Web:&nbsp;
										<a href={impressumWebsiteUrl} target='_blank' rel='noreferrer'>
											{impressumWebsiteUrl}
										</a>
										&nbsp;
									</p>
								)}
								{contactEmail && (
									<p>
										Email:&nbsp;
										<a href={`mailto:${contactEmail}`} target='_blank' rel='noreferrer'>
											{contactEmail}
										</a>
									</p>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ObjectUserDetail;
