import tipTile from "assets/images/placeholder.png";
import queryString from "query-string";
import jwt_decode from "jwt-decode";

export const disableScrolling = (value: boolean) => {
  if (value) {
    document.body.style.width = "100%";
    document.body.style.overflowY = "scroll";
    document.body.style.position = "fixed";
  } else {
    document.body.style.width = "";
    document.body.style.overflowY = "";
    document.body.style.position = "";
  }
};

export const getSinglefilterParameters = (value: string) => {
  let symbol = "";
  let maxValue = 0;
  let step = 0;
  let rightMargin = "0px";
  let widthValue = 80;
  let valueStep = 1;
  let graphLabel = "";

  if (value === "price") {
    symbol = "EUR ";
    maxValue = 1000000;
    step = 0.0001;
    rightMargin = "20px";
    widthValue = 80;
    valueStep = 10000;
    graphLabel = "priceGraph";
  }

  if (value === "space") {
    symbol = "qm";
    maxValue = 200;
    step = 0.5;
    rightMargin = "60px";
    widthValue = 40;
    valueStep = 10;
    graphLabel = "spaceGraph";
  }

  if (value === "rooms") {
    maxValue = 8;
    step = 12.5;
    rightMargin = "120px";
    widthValue = 40;
    valueStep = 1;
    graphLabel = "roomsGraph";
  }
  return {
    symbol,
    maxValue,
    step,
    rightMargin,
    widthValue,
    valueStep,
    graphLabel,
  };
};

export const getScripts = () => {
  let scriptsArray = [
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyCp3nsSMrwMgn1xRxMARGhwuygdnKxAapk&v=3.exp&libraries=places",
    "https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js",
  ];
  return scriptsArray;
};

export const setPlaceholderImg = (e: any) => {
  e.target.src = tipTile;
};

export const clearStorage = () => {
  const removeItems = [
    "propertyCount",
    "firstName",
    "username",
    "token",
    "userVerified",
    "onboarding",
    "onboardingHalfDone",
    "turnTopResult",
    "event_added_welcome",
    "event_added_Wc",
    "showWelcome",
    "userId",
    "ampSessionId",
    "ampDeviceId",
    "alertShowed",
    "utm",
    "selectedCity",
    "showWelcomePopup",
    "failed",
    "role",
    "refreshToken",
    "tryAgainCalled",
    "refreshPage",
  ];

  removeItems.forEach((item) => {
    localStorage.removeItem(item);
  });

  sessionStorage.clear();
};
export const loginUsingUrl = () => {
  if (
    window.location &&
    window.location.search &&
    window.location.search !== "" &&
    window.location.search.includes("token")
  ) {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams && queryParams.token && queryParams.token !== "") {
      const token: any = queryParams.token || "";
      localStorage.setItem("token", token);

      const decoded: any = token ? jwt_decode(token) : null;
      localStorage.setItem("language", decoded.language);

      const userId: any = queryParams.userId || "";
      localStorage.setItem("userId", userId);
      localStorage.setItem("role", "USER");
    }
  }
};

export const removeEmptyStrings = (obj: any) => {
  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      ((Array.isArray(obj[key]) && obj[key].length === 0) || obj[key] === "" ||
        obj[key] === undefined ||
        obj[key] === null ||
        obj[key] === 0)
    ) {
      delete obj[key];
    }
  }

  return obj;
};

export const getSelectedOption = (options: { label: string; value: string }[], selectedValue: string) => {
  return options?.find((item: { value: string }) => item.value === selectedValue) || '';
};

export const findRules = (fieldsName: string[], rules: {
  [key: string]: any;
}) => {
  const filteredRules = fieldsName
    .filter((key) => rules[key]) // Filters out keys that don't exist in rules
    .map((key) => ({ [key]: rules[key] })); // Maps existing keys to their respective rule values

  return Object.assign({}, ...filteredRules);
};

export const formatCurrency = (value: number) => {

  if (!value) {
    return ''
  }
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, }).format(
    value,
  );

};
