import React, { FC } from "react";
import PhoneInput from "react-phone-input-2";
import de from "react-phone-input-2/lang/de.json";
import { FormComponentProps } from "components/profile/utils/form.interface";

const PhoneNumber: FC<FormComponentProps & { value: string }> = (props) => {
  const { label, asterisk = false, error, value, ...rest } = props;
  return (
    <div className="phone-number-wrapper">
      <div className="flex align__items--center">
        {label && <label className="form-label">{label}</label>}
        {asterisk && <span className="form-asterisk">&nbsp;*</span>}
      </div>
      <div className="flex flex--column">
        <PhoneInput
          country={"de"}
          localization={de}
          specialLabel={""}
          value={value}
          inputStyle={
            error
              ? {
                  borderColor: "red",
                }
              : {}
          }
          {...rest}
        />
        {error && <span className="error-message">{error.message}</span>}
      </div>
    </div>
  );
};

export default PhoneNumber;
