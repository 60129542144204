import React, { FC, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { FieldError } from 'react-hook-form';

import 'react-quill/dist/quill.snow.css';
import TextEditor from 'components/commonComponents/reactQuill/reactQuill';

interface ImprintPopupProps {
	closePopup: () => void;
	asterisk: boolean;
	error: FieldError | undefined;
	onChange: () => void;
}

const ImprintPopup: FC<ImprintPopupProps> = (props) => {
	const quillRef = useRef<ReactQuill | null>(null);

	const { closePopup, asterisk = false, ...rest } = props;
	const [errorTxt, setErrorTxt] = useState('');

	const handleClick = () => {
		if (quillRef.current) {
			const editorLength = quillRef.current.getEditor().getText().trim().length;
			if (editorLength < 3) {
				setErrorTxt('Die Mindestlänge beträgt 3 Zeichen');
			} else if (editorLength > 512) {
				setErrorTxt('Zeichenbeschränkung überschritten');
			} else {
				closePopup();
			}
		}
	};
	return (
		<div className='imprint-modal'>
			<div className='flex align__items--center justify__content--center m-b-20'>
				<h1 className='text--center imprint-title'>Impressum</h1>
				{asterisk && <span className='form-asterisk p-0'>&nbsp;*</span>}
			</div>
			<TextEditor quillRef={quillRef} errorTxt={errorTxt} {...rest} />
			<div className='text--center mt-4 flex flex--column align__items--center'>
				<button type='button' className='save-button common-button mt-0' onClick={handleClick}>
					SPEICHERN
				</button>
			</div>
		</div>
	);
};

export default ImprintPopup;
